type Color = [red: number, green: number, blue: number, alpha: number];

export const COLOR_BLACK: Color = [0, 0, 0, 1];
export const COLOR_RED: Color = [1, 0, 0, 1];

// @see https://people.sc.fsu.edu/%7Ejburkardt/data/obj/teapot.obj
// face vertices
export const INDICES = [
  7, 6, 1, 1, 2, 7, 8, 7, 2, 2, 3, 8, 9, 8, 3, 3, 4, 9, 10, 9, 4, 4, 5, 10, 12,
  11, 6, 6, 7, 12, 13, 12, 7, 7, 8, 13, 14, 13, 8, 8, 9, 14, 15, 14, 9, 9, 10,
  15, 17, 16, 11, 11, 12, 17, 18, 17, 12, 12, 13, 18, 19, 18, 13, 13, 14, 19,
  20, 19, 14, 14, 15, 20, 22, 21, 16, 16, 17, 22, 23, 22, 17, 17, 18, 23, 24,
  23, 18, 18, 19, 24, 25, 24, 19, 19, 20, 25, 27, 26, 21, 21, 22, 27, 28, 27,
  22, 22, 23, 28, 29, 28, 23, 23, 24, 29, 30, 29, 24, 24, 25, 30, 32, 31, 26,
  26, 27, 32, 33, 32, 27, 27, 28, 33, 34, 33, 28, 28, 29, 34, 35, 34, 29, 29,
  30, 35, 37, 36, 31, 31, 32, 37, 38, 37, 32, 32, 33, 38, 39, 38, 33, 33, 34,
  39, 40, 39, 34, 34, 35, 40, 42, 41, 36, 36, 37, 42, 43, 42, 37, 37, 38, 43,
  44, 43, 38, 38, 39, 44, 45, 44, 39, 39, 40, 45, 47, 46, 41, 41, 42, 47, 48,
  47, 42, 42, 43, 48, 49, 48, 43, 43, 44, 49, 50, 49, 44, 44, 45, 50, 52, 51,
  46, 46, 47, 52, 53, 52, 47, 47, 48, 53, 54, 53, 48, 48, 49, 54, 55, 54, 49,
  49, 50, 55, 57, 56, 51, 51, 52, 57, 58, 57, 52, 52, 53, 58, 59, 58, 53, 53,
  54, 59, 60, 59, 54, 54, 55, 60, 62, 61, 56, 56, 57, 62, 63, 62, 57, 57, 58,
  63, 64, 63, 58, 58, 59, 64, 65, 64, 59, 59, 60, 65, 67, 66, 61, 61, 62, 67,
  68, 67, 62, 62, 63, 68, 69, 68, 63, 63, 64, 69, 70, 69, 64, 64, 65, 70, 72,
  71, 66, 66, 67, 72, 73, 72, 67, 67, 68, 73, 74, 73, 68, 68, 69, 74, 75, 74,
  69, 69, 70, 75, 77, 76, 71, 71, 72, 77, 78, 77, 72, 72, 73, 78, 79, 78, 73,
  73, 74, 79, 80, 79, 74, 74, 75, 80, 2, 1, 76, 76, 77, 2, 3, 2, 77, 77, 78, 3,
  4, 3, 78, 78, 79, 4, 5, 4, 79, 79, 80, 5, 85, 10, 5, 5, 81, 85, 86, 85, 81,
  81, 82, 86, 87, 86, 82, 82, 83, 87, 88, 87, 83, 83, 84, 88, 89, 15, 10, 10,
  85, 89, 90, 89, 85, 85, 86, 90, 91, 90, 86, 86, 87, 91, 92, 91, 87, 87, 88,
  92, 93, 20, 15, 15, 89, 93, 94, 93, 89, 89, 90, 94, 95, 94, 90, 90, 91, 95,
  96, 95, 91, 91, 92, 96, 97, 25, 20, 20, 93, 97, 98, 97, 93, 93, 94, 98, 99,
  98, 94, 94, 95, 99, 100, 99, 95, 95, 96, 100, 101, 30, 25, 25, 97, 101, 102,
  101, 97, 97, 98, 102, 103, 102, 98, 98, 99, 103, 104, 103, 99, 99, 100, 104,
  105, 35, 30, 30, 101, 105, 106, 105, 101, 101, 102, 106, 107, 106, 102, 102,
  103, 107, 108, 107, 103, 103, 104, 108, 109, 40, 35, 35, 105, 109, 110, 109,
  105, 105, 106, 110, 111, 110, 106, 106, 107, 111, 112, 111, 107, 107, 108,
  112, 113, 45, 40, 40, 109, 113, 114, 113, 109, 109, 110, 114, 115, 114, 110,
  110, 111, 115, 116, 115, 111, 111, 112, 116, 117, 50, 45, 45, 113, 117, 118,
  117, 113, 113, 114, 118, 119, 118, 114, 114, 115, 119, 120, 119, 115, 115,
  116, 120, 121, 55, 50, 50, 117, 121, 122, 121, 117, 117, 118, 122, 123, 122,
  118, 118, 119, 123, 124, 123, 119, 119, 120, 124, 125, 60, 55, 55, 121, 125,
  126, 125, 121, 121, 122, 126, 127, 126, 122, 122, 123, 127, 128, 127, 123,
  123, 124, 128, 129, 65, 60, 60, 125, 129, 130, 129, 125, 125, 126, 130, 131,
  130, 126, 126, 127, 131, 132, 131, 127, 127, 128, 132, 133, 70, 65, 65, 129,
  133, 134, 133, 129, 129, 130, 134, 135, 134, 130, 130, 131, 135, 136, 135,
  131, 131, 132, 136, 137, 75, 70, 70, 133, 137, 138, 137, 133, 133, 134, 138,
  139, 138, 134, 134, 135, 139, 140, 139, 135, 135, 136, 140, 141, 80, 75, 75,
  137, 141, 142, 141, 137, 137, 138, 142, 143, 142, 138, 138, 139, 143, 144,
  143, 139, 139, 140, 144, 81, 5, 80, 80, 141, 81, 82, 81, 141, 141, 142, 82,
  83, 82, 142, 142, 143, 83, 84, 83, 143, 143, 144, 84, 149, 88, 84, 84, 145,
  149, 150, 149, 145, 145, 146, 150, 151, 150, 146, 146, 147, 151, 152, 151,
  147, 147, 148, 152, 153, 92, 88, 88, 149, 153, 154, 153, 149, 149, 150, 154,
  155, 154, 150, 150, 151, 155, 156, 155, 151, 151, 152, 156, 157, 96, 92, 92,
  153, 157, 158, 157, 153, 153, 154, 158, 159, 158, 154, 154, 155, 159, 160,
  159, 155, 155, 156, 160, 161, 100, 96, 96, 157, 161, 162, 161, 157, 157, 158,
  162, 163, 162, 158, 158, 159, 163, 164, 163, 159, 159, 160, 164, 165, 104,
  100, 100, 161, 165, 166, 165, 161, 161, 162, 166, 167, 166, 162, 162, 163,
  167, 168, 167, 163, 163, 164, 168, 169, 108, 104, 104, 165, 169, 170, 169,
  165, 165, 166, 170, 171, 170, 166, 166, 167, 171, 172, 171, 167, 167, 168,
  172, 173, 112, 108, 108, 169, 173, 174, 173, 169, 169, 170, 174, 175, 174,
  170, 170, 171, 175, 176, 175, 171, 171, 172, 176, 177, 116, 112, 112, 173,
  177, 178, 177, 173, 173, 174, 178, 179, 178, 174, 174, 175, 179, 180, 179,
  175, 175, 176, 180, 181, 120, 116, 116, 177, 181, 182, 181, 177, 177, 178,
  182, 183, 182, 178, 178, 179, 183, 184, 183, 179, 179, 180, 184, 185, 124,
  120, 120, 181, 185, 186, 185, 181, 181, 182, 186, 187, 186, 182, 182, 183,
  187, 188, 187, 183, 183, 184, 188, 189, 128, 124, 124, 185, 189, 190, 189,
  185, 185, 186, 190, 191, 190, 186, 186, 187, 191, 192, 191, 187, 187, 188,
  192, 193, 132, 128, 128, 189, 193, 194, 193, 189, 189, 190, 194, 195, 194,
  190, 190, 191, 195, 196, 195, 191, 191, 192, 196, 197, 136, 132, 132, 193,
  197, 198, 197, 193, 193, 194, 198, 199, 198, 194, 194, 195, 199, 200, 199,
  195, 195, 196, 200, 201, 140, 136, 136, 197, 201, 202, 201, 197, 197, 198,
  202, 203, 202, 198, 198, 199, 203, 204, 203, 199, 199, 200, 204, 205, 144,
  140, 140, 201, 205, 206, 205, 201, 201, 202, 206, 207, 206, 202, 202, 203,
  207, 208, 207, 203, 203, 204, 208, 145, 84, 144, 144, 205, 145, 146, 145, 205,
  205, 206, 146, 147, 146, 206, 206, 207, 147, 148, 147, 207, 207, 208, 148,
  213, 152, 148, 148, 209, 213, 214, 213, 209, 209, 210, 214, 215, 214, 210,
  210, 211, 215, 212, 215, 211, 211, 212, 212, 216, 156, 152, 152, 213, 216,
  217, 216, 213, 213, 214, 217, 218, 217, 214, 214, 215, 218, 212, 218, 215,
  215, 212, 212, 219, 160, 156, 156, 216, 219, 220, 219, 216, 216, 217, 220,
  221, 220, 217, 217, 218, 221, 212, 221, 218, 218, 212, 212, 222, 164, 160,
  160, 219, 222, 223, 222, 219, 219, 220, 223, 224, 223, 220, 220, 221, 224,
  212, 224, 221, 221, 212, 212, 225, 168, 164, 164, 222, 225, 226, 225, 222,
  222, 223, 226, 227, 226, 223, 223, 224, 227, 212, 227, 224, 224, 212, 212,
  228, 172, 168, 168, 225, 228, 229, 228, 225, 225, 226, 229, 230, 229, 226,
  226, 227, 230, 212, 230, 227, 227, 212, 212, 231, 176, 172, 172, 228, 231,
  232, 231, 228, 228, 229, 232, 233, 232, 229, 229, 230, 233, 212, 233, 230,
  230, 212, 212, 234, 180, 176, 176, 231, 234, 235, 234, 231, 231, 232, 235,
  236, 235, 232, 232, 233, 236, 212, 236, 233, 233, 212, 212, 237, 184, 180,
  180, 234, 237, 238, 237, 234, 234, 235, 238, 239, 238, 235, 235, 236, 239,
  212, 239, 236, 236, 212, 212, 240, 188, 184, 184, 237, 240, 241, 240, 237,
  237, 238, 241, 242, 241, 238, 238, 239, 242, 212, 242, 239, 239, 212, 212,
  243, 192, 188, 188, 240, 243, 244, 243, 240, 240, 241, 244, 245, 244, 241,
  241, 242, 245, 212, 245, 242, 242, 212, 212, 246, 196, 192, 192, 243, 246,
  247, 246, 243, 243, 244, 247, 248, 247, 244, 244, 245, 248, 212, 248, 245,
  245, 212, 212, 249, 200, 196, 196, 246, 249, 250, 249, 246, 246, 247, 250,
  251, 250, 247, 247, 248, 251, 212, 251, 248, 248, 212, 212, 252, 204, 200,
  200, 249, 252, 253, 252, 249, 249, 250, 253, 254, 253, 250, 250, 251, 254,
  212, 254, 251, 251, 212, 212, 255, 208, 204, 204, 252, 255, 256, 255, 252,
  252, 253, 256, 257, 256, 253, 253, 254, 257, 212, 257, 254, 254, 212, 212,
  209, 148, 208, 208, 255, 209, 210, 209, 255, 255, 256, 210, 211, 210, 256,
  256, 257, 211, 212, 211, 257, 257, 212, 212, 264, 263, 258, 258, 259, 264,
  265, 264, 259, 259, 260, 265, 266, 265, 260, 260, 261, 266, 267, 266, 261,
  261, 262, 267, 269, 268, 263, 263, 264, 269, 270, 269, 264, 264, 265, 270,
  271, 270, 265, 265, 266, 271, 272, 271, 266, 266, 267, 272, 274, 273, 268,
  268, 269, 274, 275, 274, 269, 269, 270, 275, 276, 275, 270, 270, 271, 276,
  277, 276, 271, 271, 272, 277, 279, 278, 273, 273, 274, 279, 280, 279, 274,
  274, 275, 280, 281, 280, 275, 275, 276, 281, 282, 281, 276, 276, 277, 282,
  284, 283, 278, 278, 279, 284, 285, 284, 279, 279, 280, 285, 286, 285, 280,
  280, 281, 286, 287, 286, 281, 281, 282, 287, 289, 288, 283, 283, 284, 289,
  290, 289, 284, 284, 285, 290, 291, 290, 285, 285, 286, 291, 292, 291, 286,
  286, 287, 292, 294, 293, 288, 288, 289, 294, 295, 294, 289, 289, 290, 295,
  296, 295, 290, 290, 291, 296, 297, 296, 291, 291, 292, 297, 259, 258, 293,
  293, 294, 259, 260, 259, 294, 294, 295, 260, 261, 260, 295, 295, 296, 261,
  262, 261, 296, 296, 297, 262, 302, 267, 262, 262, 298, 302, 303, 302, 298,
  298, 299, 303, 304, 303, 299, 299, 300, 304, 305, 304, 300, 300, 301, 305,
  306, 272, 267, 267, 302, 306, 307, 306, 302, 302, 303, 307, 308, 307, 303,
  303, 304, 308, 309, 308, 304, 304, 305, 309, 310, 277, 272, 272, 306, 310,
  311, 310, 306, 306, 307, 311, 312, 311, 307, 307, 308, 312, 313, 312, 308,
  308, 309, 313, 314, 282, 277, 277, 310, 314, 315, 314, 310, 310, 311, 315,
  316, 315, 311, 311, 312, 316, 317, 316, 312, 312, 313, 317, 318, 287, 282,
  282, 314, 318, 319, 318, 314, 314, 315, 319, 320, 319, 315, 315, 316, 320,
  321, 320, 316, 316, 317, 321, 322, 292, 287, 287, 318, 322, 323, 322, 318,
  318, 319, 323, 324, 323, 319, 319, 320, 324, 325, 324, 320, 320, 321, 325,
  326, 297, 292, 292, 322, 326, 327, 326, 322, 322, 323, 327, 328, 327, 323,
  323, 324, 328, 329, 328, 324, 324, 325, 329, 298, 262, 297, 297, 326, 298,
  299, 298, 326, 326, 327, 299, 300, 299, 327, 327, 328, 300, 301, 300, 328,
  328, 329, 301, 336, 335, 330, 330, 331, 336, 337, 336, 331, 331, 332, 337,
  338, 337, 332, 332, 333, 338, 339, 338, 333, 333, 334, 339, 341, 340, 335,
  335, 336, 341, 342, 341, 336, 336, 337, 342, 343, 342, 337, 337, 338, 343,
  344, 343, 338, 338, 339, 344, 346, 345, 340, 340, 341, 346, 347, 346, 341,
  341, 342, 347, 348, 347, 342, 342, 343, 348, 349, 348, 343, 343, 344, 349,
  351, 350, 345, 345, 346, 351, 352, 351, 346, 346, 347, 352, 353, 352, 347,
  347, 348, 353, 354, 353, 348, 348, 349, 354, 356, 355, 350, 350, 351, 356,
  357, 356, 351, 351, 352, 357, 358, 357, 352, 352, 353, 358, 359, 358, 353,
  353, 354, 359, 361, 360, 355, 355, 356, 361, 362, 361, 356, 356, 357, 362,
  363, 362, 357, 357, 358, 363, 364, 363, 358, 358, 359, 364, 366, 365, 360,
  360, 361, 366, 367, 366, 361, 361, 362, 367, 368, 367, 362, 362, 363, 368,
  369, 368, 363, 363, 364, 369, 331, 330, 365, 365, 366, 331, 332, 331, 366,
  366, 367, 332, 333, 332, 367, 367, 368, 333, 334, 333, 368, 368, 369, 334,
  374, 339, 334, 334, 370, 374, 375, 374, 370, 370, 371, 375, 376, 375, 371,
  371, 372, 376, 377, 376, 372, 372, 373, 377, 378, 344, 339, 339, 374, 378,
  379, 378, 374, 374, 375, 379, 380, 379, 375, 375, 376, 380, 381, 380, 376,
  376, 377, 381, 382, 349, 344, 344, 378, 382, 383, 382, 378, 378, 379, 383,
  384, 383, 379, 379, 380, 384, 385, 384, 380, 380, 381, 385, 386, 354, 349,
  349, 382, 386, 387, 386, 382, 382, 383, 387, 388, 387, 383, 383, 384, 388,
  389, 388, 384, 384, 385, 389, 390, 359, 354, 354, 386, 390, 391, 390, 386,
  386, 387, 391, 392, 391, 387, 387, 388, 392, 393, 392, 388, 388, 389, 393,
  394, 364, 359, 359, 390, 394, 395, 394, 390, 390, 391, 395, 396, 395, 391,
  391, 392, 396, 397, 396, 392, 392, 393, 397, 398, 369, 364, 364, 394, 398,
  399, 398, 394, 394, 395, 399, 400, 399, 395, 395, 396, 400, 401, 400, 396,
  396, 397, 401, 370, 334, 369, 369, 398, 370, 371, 370, 398, 398, 399, 371,
  372, 371, 399, 399, 400, 372, 373, 372, 400, 400, 401, 373, 407, 402, 402,
  402, 403, 407, 408, 407, 403, 403, 404, 408, 409, 408, 404, 404, 405, 409,
  410, 409, 405, 405, 406, 410, 411, 402, 402, 402, 407, 411, 412, 411, 407,
  407, 408, 412, 413, 412, 408, 408, 409, 413, 414, 413, 409, 409, 410, 414,
  415, 402, 402, 402, 411, 415, 416, 415, 411, 411, 412, 416, 417, 416, 412,
  412, 413, 417, 418, 417, 413, 413, 414, 418, 419, 402, 402, 402, 415, 419,
  420, 419, 415, 415, 416, 420, 421, 420, 416, 416, 417, 421, 422, 421, 417,
  417, 418, 422, 423, 402, 402, 402, 419, 423, 424, 423, 419, 419, 420, 424,
  425, 424, 420, 420, 421, 425, 426, 425, 421, 421, 422, 426, 427, 402, 402,
  402, 423, 427, 428, 427, 423, 423, 424, 428, 429, 428, 424, 424, 425, 429,
  430, 429, 425, 425, 426, 430, 431, 402, 402, 402, 427, 431, 432, 431, 427,
  427, 428, 432, 433, 432, 428, 428, 429, 433, 434, 433, 429, 429, 430, 434,
  435, 402, 402, 402, 431, 435, 436, 435, 431, 431, 432, 436, 437, 436, 432,
  432, 433, 437, 438, 437, 433, 433, 434, 438, 439, 402, 402, 402, 435, 439,
  440, 439, 435, 435, 436, 440, 441, 440, 436, 436, 437, 441, 442, 441, 437,
  437, 438, 442, 443, 402, 402, 402, 439, 443, 444, 443, 439, 439, 440, 444,
  445, 444, 440, 440, 441, 445, 446, 445, 441, 441, 442, 446, 447, 402, 402,
  402, 443, 447, 448, 447, 443, 443, 444, 448, 449, 448, 444, 444, 445, 449,
  450, 449, 445, 445, 446, 450, 451, 402, 402, 402, 447, 451, 452, 451, 447,
  447, 448, 452, 453, 452, 448, 448, 449, 453, 454, 453, 449, 449, 450, 454,
  455, 402, 402, 402, 451, 455, 456, 455, 451, 451, 452, 456, 457, 456, 452,
  452, 453, 457, 458, 457, 453, 453, 454, 458, 459, 402, 402, 402, 455, 459,
  460, 459, 455, 455, 456, 460, 461, 460, 456, 456, 457, 461, 462, 461, 457,
  457, 458, 462, 463, 402, 402, 402, 459, 463, 464, 463, 459, 459, 460, 464,
  465, 464, 460, 460, 461, 465, 466, 465, 461, 461, 462, 466, 403, 402, 402,
  402, 463, 403, 404, 403, 463, 463, 464, 404, 405, 404, 464, 464, 465, 405,
  406, 405, 465, 465, 466, 406, 471, 410, 406, 406, 467, 471, 472, 471, 467,
  467, 468, 472, 473, 472, 468, 468, 469, 473, 474, 473, 469, 469, 470, 474,
  475, 414, 410, 410, 471, 475, 476, 475, 471, 471, 472, 476, 477, 476, 472,
  472, 473, 477, 478, 477, 473, 473, 474, 478, 479, 418, 414, 414, 475, 479,
  480, 479, 475, 475, 476, 480, 481, 480, 476, 476, 477, 481, 482, 481, 477,
  477, 478, 482, 483, 422, 418, 418, 479, 483, 484, 483, 479, 479, 480, 484,
  485, 484, 480, 480, 481, 485, 486, 485, 481, 481, 482, 486, 487, 426, 422,
  422, 483, 487, 488, 487, 483, 483, 484, 488, 489, 488, 484, 484, 485, 489,
  490, 489, 485, 485, 486, 490, 491, 430, 426, 426, 487, 491, 492, 491, 487,
  487, 488, 492, 493, 492, 488, 488, 489, 493, 494, 493, 489, 489, 490, 494,
  495, 434, 430, 430, 491, 495, 496, 495, 491, 491, 492, 496, 497, 496, 492,
  492, 493, 497, 498, 497, 493, 493, 494, 498, 499, 438, 434, 434, 495, 499,
  500, 499, 495, 495, 496, 500, 501, 500, 496, 496, 497, 501, 502, 501, 497,
  497, 498, 502, 503, 442, 438, 438, 499, 503, 504, 503, 499, 499, 500, 504,
  505, 504, 500, 500, 501, 505, 506, 505, 501, 501, 502, 506, 507, 446, 442,
  442, 503, 507, 508, 507, 503, 503, 504, 508, 509, 508, 504, 504, 505, 509,
  510, 509, 505, 505, 506, 510, 511, 450, 446, 446, 507, 511, 512, 511, 507,
  507, 508, 512, 513, 512, 508, 508, 509, 513, 514, 513, 509, 509, 510, 514,
  515, 454, 450, 450, 511, 515, 516, 515, 511, 511, 512, 516, 517, 516, 512,
  512, 513, 517, 518, 517, 513, 513, 514, 518, 519, 458, 454, 454, 515, 519,
  520, 519, 515, 515, 516, 520, 521, 520, 516, 516, 517, 521, 522, 521, 517,
  517, 518, 522, 523, 462, 458, 458, 519, 523, 524, 523, 519, 519, 520, 524,
  525, 524, 520, 520, 521, 525, 526, 525, 521, 521, 522, 526, 527, 466, 462,
  462, 523, 527, 528, 527, 523, 523, 524, 528, 529, 528, 524, 524, 525, 529,
  530, 529, 525, 525, 526, 530, 467, 406, 466, 466, 527, 467, 468, 467, 527,
  527, 528, 468, 469, 468, 528, 528, 529, 469, 470, 469, 529, 529, 530, 470,
].map((x) => x - 1);

// normals
export const NORMALS = [
  -0.966742, -0.255752, 9.97231e-9, -0.966824, 0.255443, 3.11149e-8, -0.092052,
  0.995754, 4.45989e-8, 0.68205, 0.731305, 0, 0.870301, 0.492521, -4.87195e-9,
  -0.893014, -0.256345, -0.369882, -0.893437, 0.255997, -0.369102, -0.0838771,
  0.995843, -0.0355068, 0.629724, 0.73186, 0.260439, 0.803725, 0.49337,
  0.332584, -0.683407, -0.256729, -0.683407, -0.683531, 0.256067, -0.683531,
  -0.0649249, 0.995776, -0.0649248, 0.481398, 0.732469, 0.481398, 0.614804,
  0.493997, 0.614804, -0.369882, -0.256345, -0.893014, -0.369102, 0.255997,
  -0.893437, -0.0355067, 0.995843, -0.0838772, 0.260439, 0.73186, 0.629724,
  0.332584, 0.49337, 0.803725, -0.00284834, -0.257863, -0.966177, -0.00192311,
  0.254736, -0.967009, -0.000266114, 0.995734, -0.0922702, 2.39288e-5, 0.731295,
  0.682061, 2.43342e-9, 0.492521, 0.870301, 0.379058, -0.3593, -0.852771,
  0.37711, 0.149086, -0.914091, 0.0275022, 0.992081, -0.122551, -0.26101,
  0.726762, 0.635367, -0.332485, 0.492546, 0.804271, 0.663548, -0.410791,
  -0.625264, 0.712664, 0.0737216, -0.697621, 0.0997268, 0.987509, -0.121984,
  -0.48732, 0.723754, 0.488568, -0.615242, 0.492602, 0.615484, 0.880028,
  -0.332908, -0.338709, 0.917276, 0.167113, -0.361493, 0.113584, 0.992365,
  -0.0480695, -0.63415, 0.727508, 0.261889, -0.804126, 0.492634, 0.332705,
  0.96669, -0.255738, 0.0104537, 0.967442, 0.252962, 0.00810329, 0.0934365,
  0.995624, 0.00128063, -0.682167, 0.731196, -0.00034353, -0.870322, 0.492483,
  -5.42436e-5, 0.893014, -0.256345, 0.369882, 0.893437, 0.255997, 0.369102,
  0.0838768, 0.995843, 0.0355066, -0.629724, 0.73186, -0.260439, -0.803725,
  0.49337, -0.332584, 0.683407, -0.256729, 0.683407, 0.683531, 0.256067,
  0.683531, 0.0649249, 0.995776, 0.0649249, -0.481398, 0.732469, -0.481398,
  -0.614804, 0.493997, -0.614804, 0.369882, -0.256345, 0.893014, 0.369102,
  0.255997, 0.893437, 0.0355067, 0.995843, 0.083877, -0.260439, 0.73186,
  -0.629724, -0.332584, 0.49337, -0.803725, 3.83985e-9, -0.255752, 0.966742,
  2.59359e-9, 0.255443, 0.966824, 3.99081e-8, 0.995754, 0.092052, 1.03862e-8,
  0.731305, -0.68205, -2.43342e-9, 0.492521, -0.870301, -0.369882, -0.256345,
  0.893014, -0.369102, 0.255996, 0.893437, -0.0355068, 0.995843, 0.0838771,
  0.260439, 0.73186, -0.629724, 0.332584, 0.49337, -0.803725, -0.683407,
  -0.256729, 0.683407, -0.683531, 0.256067, 0.683531, -0.0649249, 0.995776,
  0.064925, 0.481398, 0.732469, -0.481398, 0.614804, 0.493997, -0.614804,
  -0.893014, -0.256345, 0.369882, -0.893437, 0.255997, 0.369102, -0.0838767,
  0.995843, 0.0355066, 0.629724, 0.73186, -0.260439, 0.803725, 0.49337,
  -0.332584, 0.915321, 0.402725, 4.83311e-9, 0.941808, 0.336151, -4.85769e-9,
  0.97869, 0.205342, 4.90003e-9, 0.997804, -0.0662397, 1.0073e-8, 0.845438,
  0.403546, 0.349835, 0.869996, 0.336859, 0.360047, 0.904193, 0.205791, 0.37428,
  0.921879, -0.0663697, 0.381752, 0.646802, 0.404096, 0.646802, 0.665655,
  0.337351, 0.665655, 0.691923, 0.20612, 0.691923, 0.705542, -0.0664796,
  0.705543, 0.349835, 0.403546, 0.845438, 0.360047, 0.336859, 0.869996, 0.37428,
  0.205791, 0.904193, 0.381752, -0.0663697, 0.921879, -1.31462e-9, 0.402725,
  0.915321, 9.76689e-10, 0.336151, 0.941808, -1.9304e-8, 0.205342, 0.97869,
  -2.15056e-8, -0.0662397, 0.997804, -0.349835, 0.403546, 0.845438, -0.360047,
  0.336859, 0.869996, -0.37428, 0.205791, 0.904193, -0.381752, -0.0663697,
  0.921879, -0.646802, 0.404096, 0.646802, -0.665655, 0.337351, 0.665655,
  -0.691923, 0.20612, 0.691923, -0.705543, -0.0664796, 0.705543, -0.845438,
  0.403546, 0.349835, -0.869996, 0.336859, 0.360047, -0.904193, 0.205791,
  0.37428, -0.921879, -0.0663697, 0.381752, -0.915321, 0.402725, -2.41655e-9,
  -0.941808, 0.336151, -1.21442e-8, -0.97869, 0.205342, -3.18502e-8, -0.997804,
  -0.0662397, -2.26643e-8, -0.845438, 0.403546, -0.349835, -0.869996, 0.336859,
  -0.360047, -0.904193, 0.205791, -0.37428, -0.921879, -0.0663697, -0.381752,
  -0.646802, 0.404096, -0.646802, -0.665655, 0.337351, -0.665655, -0.691923,
  0.20612, -0.691923, -0.705542, -0.0664796, -0.705543, -0.349835, 0.403546,
  -0.845438, -0.360047, 0.336859, -0.869996, -0.37428, 0.205791, -0.904193,
  -0.381752, -0.0663697, -0.921879, 1.31462e-9, 0.402725, -0.915321,
  -9.76689e-10, 0.336151, -0.941808, 1.9304e-8, 0.205342, -0.97869, 2.15056e-8,
  -0.0662397, -0.997804, 0.349835, 0.403546, -0.845438, 0.360047, 0.336859,
  -0.869996, 0.37428, 0.205791, -0.904193, 0.381752, -0.0663697, -0.921879,
  0.646802, 0.404096, -0.646802, 0.665655, 0.337351, -0.665655, 0.691923,
  0.20612, -0.691923, 0.705543, -0.0664796, -0.705542, 0.845438, 0.403546,
  -0.349835, 0.869996, 0.336859, -0.360047, 0.904193, 0.205791, -0.37428,
  0.921879, -0.0663697, -0.381752, 0.900182, -0.435513, -1.50883e-8, 0.729611,
  -0.683863, -9.71212e-9, 0.693951, -0.720022, -9.54282e-9, 0.79395, -0.607984,
  2.6277e-8, 0.831437, -0.43618, 0.344179, 0.673512, -0.684665, 0.278594,
  0.640399, -0.720924, 0.264874, 0.732949, -0.608996, 0.303166, 0.636092,
  -0.436777, 0.636092, 0.514965, -0.685289, 0.514965, 0.489651, -0.721446,
  0.489651, 0.560555, -0.609554, 0.560555, 0.344179, -0.43618, 0.831437,
  0.278594, -0.684665, 0.673512, 0.264874, -0.720924, 0.640399, 0.303166,
  -0.608996, 0.732949, 1.18057e-8, -0.435513, 0.900182, -4.75784e-9, -0.683863,
  0.729611, 9.10217e-9, -0.720022, 0.693951, -2.68996e-8, -0.607984, 0.79395,
  -0.344179, -0.43618, 0.831437, -0.278594, -0.684665, 0.673512, -0.264874,
  -0.720924, 0.640399, -0.303166, -0.608996, 0.732949, -0.636092, -0.436777,
  0.636092, -0.514965, -0.685289, 0.514965, -0.489651, -0.721446, 0.489651,
  -0.560555, -0.609554, 0.560555, -0.831437, -0.43618, 0.344179, -0.673512,
  -0.684665, 0.278595, -0.640399, -0.720924, 0.264874, -0.732949, -0.608996,
  0.303166, -0.900182, -0.435513, -7.54414e-9, -0.729611, -0.683863,
  -7.28409e-9, -0.693951, -0.720022, 4.77141e-9, -0.79395, -0.607983,
  -2.6277e-8, -0.831437, -0.43618, -0.344179, -0.673512, -0.684665, -0.278594,
  -0.640399, -0.720924, -0.264874, -0.732949, -0.608996, -0.303166, -0.636092,
  -0.436777, -0.636092, -0.514965, -0.685289, -0.514965, -0.489651, -0.721446,
  -0.489651, -0.560555, -0.609554, -0.560555, -0.344179, -0.43618, -0.831437,
  -0.278594, -0.684665, -0.673512, -0.264874, -0.720924, -0.640399, -0.303166,
  -0.608996, -0.732949, -1.18057e-8, -0.435513, -0.900182, 4.75784e-9,
  -0.683863, -0.729611, -9.10217e-9, -0.720022, -0.693951, 2.68996e-8,
  -0.607984, -0.79395, 0.344179, -0.43618, -0.831437, 0.278594, -0.684665,
  -0.673512, 0.264874, -0.720924, -0.640399, 0.303167, -0.608996, -0.732949,
  0.636092, -0.436777, -0.636092, 0.514965, -0.685289, -0.514965, 0.489651,
  -0.721446, -0.489651, 0.560555, -0.609554, -0.560555, 0.831437, -0.43618,
  -0.344179, 0.673512, -0.684665, -0.278595, 0.640399, -0.720924, -0.264874,
  0.732949, -0.608996, -0.303166, 0.62386, -0.781536, 3.04248e-8, 0.177291,
  -0.984159, -3.28321e-9, 0.0492072, -0.998789, 1.48326e-9, 1.94668e-11, -1,
  -7.78368e-10, 0.576229, -0.781801, 0.238217, 0.163629, -0.984208, 0.0675273,
  0.0454217, -0.998792, 0.0187357, 0.440416, -0.782348, 0.440416, 0.124903,
  -0.984276, 0.124903, 0.0346621, -0.998798, 0.0346621, 0.238217, -0.781801,
  0.576229, 0.0675273, -0.984208, 0.163629, 0.0187357, -0.998792, 0.0454217,
  -3.18434e-8, -0.781536, 0.62386, 3.33958e-9, -0.984159, 0.177291, -1.80438e-9,
  -0.998789, 0.0492072, -0.238216, -0.781801, 0.576229, -0.0675273, -0.984208,
  0.163629, -0.0187357, -0.998792, 0.0454217, -0.440416, -0.782348, 0.440416,
  -0.124903, -0.984276, 0.124903, -0.0346621, -0.998798, 0.0346621, -0.576229,
  -0.781801, 0.238217, -0.163629, -0.984208, 0.0675273, -0.0454217, -0.998792,
  0.0187357, -0.62386, -0.781536, -3.17476e-8, -0.177291, -0.984159, 3.13397e-9,
  -0.0492072, -0.998789, -1.33493e-9, -0.576229, -0.781801, -0.238217,
  -0.163629, -0.984208, -0.0675273, -0.0454217, -0.998792, -0.0187357,
  -0.440416, -0.782348, -0.440416, -0.124903, -0.984276, -0.124903, -0.0346621,
  -0.998798, -0.0346621, -0.238217, -0.781801, -0.576229, -0.0675273, -0.984208,
  -0.163629, -0.0187357, -0.998792, -0.0454217, 3.18434e-8, -0.781536, -0.62386,
  -3.28168e-9, -0.984159, -0.177291, 1.46144e-9, -0.998789, -0.0492072,
  0.238217, -0.781801, -0.576229, 0.0675273, -0.984208, -0.163629, 0.0187357,
  -0.998792, -0.0454217, 0.440416, -0.782348, -0.440416, 0.124903, -0.984276,
  -0.124903, 0.0346621, -0.998798, -0.0346621, 0.576229, -0.781801, -0.238217,
  0.163629, -0.984208, -0.0675273, 0.0454217, -0.998792, -0.0187357, 0.00778619,
  -0.99997, -0.000215809, 0.0391385, -0.999233, -0.000988567, 0.179511,
  -0.983746, -0.00436856, 0.6123, -0.790556, -0.0104598, 0.986152, -0.165707,
  -0.00666949, 0.00703893, -0.812495, 0.582926, 0.0361273, -0.837257, 0.545614,
  0.161845, -0.810421, 0.563048, 0.482365, -0.595148, 0.642746, 0.73872,
  -0.114593, 0.664199, -0.00190867, 0.162121, 0.986769, 0.0027616, 0.0171073,
  0.99985, 0.0105326, 0.0733989, 0.997247, -0.0660406, 0.130069, 0.989303,
  -0.0944272, 0.0165946, 0.995393, -0.009203, 0.871509, 0.490293, -0.0486064,
  0.840609, 0.539457, -0.223298, 0.802881, 0.552739, -0.596365, 0.559971,
  0.575135, -0.803337, 0.0682361, 0.591602, -0.0105609, 0.999944, 0.000103364,
  -0.0587986, 0.99827, 0.000709759, -0.28071, 0.959787, 0.00326876, -0.749723,
  0.661738, 0.0042684, -0.997351, 0.0727144, 0.00205923, -0.00879197, 0.871493,
  -0.49033, -0.0464937, 0.841178, -0.538756, -0.217909, 0.806807, -0.549161,
  -0.597291, 0.560026, -0.574121, -0.804, 0.0629127, -0.591291, -0.00180555,
  0.161691, -0.98684, 0.00203087, 0.014555, -0.999892, 0.00921499, 0.0600698,
  -0.998152, -0.0593333, 0.113865, -0.991723, -0.0868992, 0.0122903, -0.996141,
  0.00641779, -0.812379, -0.583094, 0.0337833, -0.837512, -0.545373, 0.157112,
  -0.811947, -0.56219, 0.484407, -0.589365, -0.646528, 0.73887, -0.10132,
  -0.666187, 0.946512, 0.32265, -0.0033571, 0.82583, 0.56387, -0.00745213,
  0.650011, 0.759893, -0.00693681, 0.532429, 0.846458, -0.00524544, 0.725608,
  0.259351, 0.637362, 0.645945, 0.461988, 0.607719, 0.531614, 0.63666, 0.558615,
  0.424964, 0.681717, 0.59554, -0.0495616, -0.019755, 0.998576, -0.0378162,
  -0.0356243, 0.99865, -0.0379139, -0.0365122, 0.998614, -0.168854, -0.297946,
  0.93953, -0.742342, -0.299166, 0.599523, -0.619602, -0.529406, 0.579503,
  -0.483708, -0.685761, 0.543837, -0.445293, -0.794355, 0.413176, -0.926513,
  -0.376257, 0.00199587, -0.75392, -0.656952, 0.00431723, -0.566224, -0.824244,
  0.00346105, -0.481804, -0.876277, 0.00185047, -0.744675, -0.294424, -0.598977,
  -0.621949, -0.528114, -0.578165, -0.481171, -0.68834, -0.542828, -0.438055,
  -0.797035, -0.415744, -0.0443368, -0.0170558, -0.998871, -0.0261761,
  -0.0281665, -0.99926, -0.0252939, -0.0283323, -0.999278, -0.157482, -0.289392,
  -0.944167, 0.728244, 0.25241, -0.637142, 0.647055, 0.459725, -0.608254,
  0.522994, 0.640657, -0.562171, 0.409978, 0.682857, -0.604669, -0.230787,
  0.972982, -0.00652338, -0.548936, 0.835863, -0.00151111, -0.875671, 0.482807,
  0.00989278, -0.877554, 0.479097, 0.0190923, -0.69619, 0.717439, 0.024497,
  -0.152878, 0.687211, 0.71019, -0.316721, 0.63775, 0.702113, -0.601067,
  0.471452, 0.64533, -0.635889, 0.44609, 0.6298, -0.435746, 0.601008, 0.670011,
  0.111112, -0.0850694, 0.99016, 0.22331, 0.00654036, 0.974726, 0.190097,
  0.154964, 0.969458, 0.00527077, 0.189482, 0.98187, -0.0117518, 0.246688,
  0.969024, 0.343906, -0.722796, 0.599412, 0.572489, -0.567656, 0.591627,
  0.787436, -0.256459, 0.560512, 0.647097, -0.306374, 0.698141, 0.427528,
  -0.499343, 0.753576, 0.410926, -0.911668, 0.00128446, 0.67152, -0.740986,
  -0.000899122, 0.922026, -0.38706, -0.00725269, 0.84691, -0.531556, -0.0138542,
  0.535925, -0.8442, -0.0105045, 0.341188, -0.722822, -0.600931, 0.578664,
  -0.561139, -0.591838, 0.784869, -0.25102, -0.566542, 0.642681, -0.302257,
  -0.70399, 0.418589, -0.500042, -0.758117, 0.115806, -0.0791394, -0.990114,
  0.232811, 0.0125652, -0.972441, 0.206662, 0.153601, -0.96628, 0.0244996,
  0.161443, -0.986578, 0.00338193, 0.211115, -0.977455, -0.134912, 0.687491,
  -0.713551, -0.31954, 0.633073, -0.705062, -0.603902, 0.461442, -0.649903,
  -0.631816, 0.437169, -0.640072, -0.424306, 0.612706, -0.66675, -0.4258,
  0.904753, 0.0108049, 0.0220472, 0.999756, 0.00162273, 0.999599, 0.0258705,
  0.0115556, 0.709585, -0.704553, 0.00967183, -0.259858, 0.791936, 0.552549,
  0.00953916, 0.99972, -0.0216718, 0.410156, 0.332912, -0.849083, 0.541523,
  -0.54862, -0.637, 0.0463104, 0.455224, 0.889172, -0.0106883, 0.988794,
  0.148901, -0.0443756, 0.682947, -0.729118, 0.122825, 0.00923214, -0.992385,
  0.481839, -0.180439, 0.85748, 0.455272, 0.736752, 0.499925, -0.220542,
  0.907193, -0.358276, -0.23592, 0.657249, -0.715797, 0.728092, -0.685302,
  -0.0155853, 0.888739, 0.45811, -0.0166791, -0.260097, 0.965582, 0.000800195,
  -0.371612, 0.928378, -0.00441745, 0.480166, -0.17836, -0.858853, 0.488103,
  0.716801, -0.497947, -0.222004, 0.905399, 0.361893, -0.235405, 0.66318,
  0.710477, 0.0587203, 0.437704, -0.8972, 0.00132612, 0.986459, -0.164003,
  -0.0441901, 0.681677, 0.730317, 0.138801, -0.0341896, 0.98973, -0.25889,
  0.797206, -0.54538, 0.0122703, 0.999739, 0.0192865, 0.39863, 0.35489,
  0.845663, 0.537564, -0.5814, 0.610737, -7.79193e-10, 1, 6.50944e-9, 0.82454,
  0.565804, 1.72913e-5, 0.917701, -0.397272, 3.35502e-5, 0.935269, -0.353939,
  0.000112842, 0.780712, 0.624891, 7.51916e-5, 0.762641, 0.565035, 0.314825,
  0.847982, -0.397998, 0.350034, 0.864141, -0.355261, 0.356441, 0.720991,
  0.625625, 0.297933, 0.583357, 0.565165, 0.583338, 0.648485, -0.398726,
  0.648448, 0.660872, -0.355894, 0.660748, 0.551862, 0.62529, 0.55178, 0.314824,
  0.565051, 0.762629, 0.350045, -0.397976, 0.847988, 0.356474, -0.3552,
  0.864153, 0.297983, 0.625515, 0.721067, -1.7299e-5, 0.565804, 0.82454,
  -3.35448e-5, -0.397272, 0.917701, -0.000112839, -0.353939, 0.935269,
  -7.51869e-5, 0.624891, 0.780712, -0.314825, 0.565035, 0.762641, -0.350034,
  -0.397998, 0.847982, -0.356441, -0.355261, 0.864141, -0.297933, 0.625625,
  0.720991, -0.583338, 0.565165, 0.583357, -0.648448, -0.398726, 0.648485,
  -0.660748, -0.355894, 0.660872, -0.55178, 0.62529, 0.551862, -0.762629,
  0.565051, 0.314824, -0.847988, -0.397976, 0.350045, -0.864153, -0.3552,
  0.356474, -0.721067, 0.625515, 0.297983, -0.82454, 0.565804, -1.72877e-5,
  -0.917701, -0.397272, -3.35262e-5, -0.935269, -0.353939, -0.000112839,
  -0.780712, 0.624891, -7.51882e-5, -0.76264, 0.565035, -0.314825, -0.847982,
  -0.397998, -0.350034, -0.864141, -0.355261, -0.356441, -0.720991, 0.625625,
  -0.297933, -0.583357, 0.565165, -0.583338, -0.648485, -0.398726, -0.648448,
  -0.660872, -0.355894, -0.660748, -0.551862, 0.62529, -0.55178, -0.314824,
  0.565051, -0.762629, -0.350045, -0.397976, -0.847988, -0.356474, -0.3552,
  -0.864153, -0.297983, 0.625515, -0.721067, 1.72918e-5, 0.565804, -0.82454,
  3.35344e-5, -0.397272, -0.917701, 0.000112839, -0.353939, -0.935269,
  7.51869e-5, 0.624891, -0.780712, 0.314825, 0.565035, -0.762641, 0.350034,
  -0.397998, -0.847982, 0.356441, -0.355261, -0.864141, 0.297933, 0.625625,
  -0.720991, 0.583338, 0.565165, -0.583357, 0.648448, -0.398726, -0.648485,
  0.660748, -0.355894, -0.660872, 0.55178, 0.62529, -0.551862, 0.762629,
  0.565051, -0.314824, 0.847988, -0.397976, -0.350045, 0.864153, -0.3552,
  -0.356474, 0.721067, 0.625515, -0.297983, 0.236584, 0.971611, 8.31862e-9,
  0.173084, 0.984907, -1.18677e-9, 0.379703, 0.925108, 2.44118e-9, 0.526673,
  0.850068, 2.66504e-9, 0.217978, 0.971775, 0.0902162, 0.15959, 0.984977,
  0.0659615, 0.350498, 0.925312, 0.14474, 0.48559, 0.850653, 0.201474, 0.166631,
  0.971838, 0.166631, 0.121908, 0.985026, 0.121908, 0.267668, 0.925585,
  0.267668, 0.371315, 0.851029, 0.371315, 0.0902162, 0.971775, 0.217978,
  0.0659615, 0.984977, 0.15959, 0.14474, 0.925312, 0.350498, 0.201474, 0.850653,
  0.48559, -8.2649e-9, 0.971611, 0.236584, 1.37744e-9, 0.984907, 0.173084,
  2.79781e-10, 0.925108, 0.379703, 2.55497e-9, 0.850068, 0.526673, -0.0902162,
  0.971775, 0.217978, -0.0659615, 0.984977, 0.15959, -0.14474, 0.925312,
  0.350498, -0.201474, 0.850653, 0.48559, -0.166631, 0.971838, 0.166631,
  -0.121908, 0.985026, 0.121908, -0.267668, 0.925585, 0.267668, -0.371315,
  0.851029, 0.371315, -0.217978, 0.971775, 0.0902162, -0.15959, 0.984977,
  0.0659615, -0.350498, 0.925312, 0.14474, -0.48559, 0.850653, 0.201474,
  -0.236583, 0.971611, -6.23897e-9, -0.173084, 0.984907, 2.37354e-9, -0.379703,
  0.925108, -2.44118e-9, -0.526673, 0.850068, 0, -0.217978, 0.971775,
  -0.0902162, -0.15959, 0.984977, -0.0659615, -0.350498, 0.925312, -0.14474,
  -0.48559, 0.850653, -0.201474, -0.166631, 0.971838, -0.166631, -0.121908,
  0.985026, -0.121908, -0.267668, 0.925585, -0.267668, -0.371315, 0.851029,
  -0.371315, -0.0902162, 0.971775, -0.217978, -0.0659615, 0.984977, -0.15959,
  -0.14474, 0.925312, -0.350498, -0.201474, 0.850653, -0.485589, 6.16189e-9,
  0.971611, -0.236584, -1.37744e-9, 0.984907, -0.173084, -2.79781e-10, 0.925108,
  -0.379703, -2.55497e-9, 0.850068, -0.526673, 0.0902162, 0.971775, -0.217978,
  0.0659615, 0.984977, -0.15959, 0.14474, 0.925312, -0.350498, 0.201474,
  0.850653, -0.48559, 0.166631, 0.971838, -0.166631, 0.121908, 0.985026,
  -0.121908, 0.267668, 0.925585, -0.267668, 0.371315, 0.851029, -0.371315,
  0.217978, 0.971775, -0.0902162, 0.15959, 0.984977, -0.0659615, 0.350498,
  0.925312, -0.14474, 0.48559, 0.850653, -0.201474,
];

// vertex positions
export const POSITIONS = [
  1.3542200000000002, 0.9448566666666667, -0.036934666666666664,
  1.3357133333333333, 1.01481, -0.036934666666666664, 1.3571833333333332,
  1.0381266666666666, -0.036934666666666664, 1.4008566666666666, 1.01481,
  -0.036934666666666664, 1.4489733333333334, 0.9448566666666667,
  -0.036934666666666664, 1.2514166666666666, 0.9448566666666667,
  0.48372333333333334, 1.2343433333333331, 1.01481, 0.47646, 1.2541466666666667,
  1.0381266666666666, 0.48488666666666663, 1.2944366666666667, 1.01481, 0.50203,
  1.3388233333333335, 0.9448566666666667, 0.5209133333333333, 0.96953,
  0.9448566666666667, 0.9048933333333333, 0.9563900000000001, 1.01481,
  0.8917566666666666, 0.9716333333333333, 1.0381266666666666,
  0.9069966666666667, 1.00264, 1.01481, 0.9380066666666667, 1.0368033333333333,
  0.9448566666666667, 0.9721666666666666, 0.5483600000000001,
  0.9448566666666667, 1.18678, 0.5410966666666667, 1.01481, 1.1697066666666667,
  0.5495233333333334, 1.0381266666666666, 1.1895099999999998,
  0.5666633333333333, 1.01481, 1.2298, 0.58555, 0.9448566666666667,
  1.2741866666666666, 0.027700833333333334, 0.9448566666666667,
  1.2895866666666669, 0.027700833333333334, 1.01481, 1.27108,
  0.027700833333333334, 1.0381266666666666, 1.2925466666666667,
  0.027700833333333334, 1.01481, 1.33622, 0.027700833333333334,
  0.9448566666666667, 1.3843366666666665, -0.5289333333333334,
  0.9448566666666667, 1.18678, -0.5008733333333333, 1.01481, 1.1697066666666667,
  -0.4986166666666667, 1.0381266666666666, 1.1895099999999998,
  -0.5118233333333333, 1.01481, 1.2298, -0.5301466666666667, 0.9448566666666667,
  1.2741866666666666, -0.9461066666666667, 0.9448566666666667,
  0.9048933333333333, -0.91448, 1.01481, 0.8917566666666666,
  -0.9202266666666666, 1.0381266666666666, 0.9069966666666667, -0.94774,
  1.01481, 0.9380066666666667, -0.9814033333333333, 0.9448566666666667,
  0.9721666666666666, -1.2080066666666667, 0.9448566666666667,
  0.48372333333333334, -1.1840000000000002, 1.01481, 0.47646,
  -1.2002433333333333, 1.0381266666666666, 0.48488666666666663,
  -1.2392233333333331, 1.01481, 0.50203, -1.2834233333333331,
  0.9448566666666667, 0.5209133333333333, -1.2988199999999999,
  0.9448566666666667, -0.036934666666666664, -1.2803133333333332, 1.01481,
  -0.036934666666666664, -1.3017800000000002, 1.0381266666666666,
  -0.036934666666666664, -1.3454533333333332, 1.01481, -0.036934666666666664,
  -1.39357, 0.9448566666666667, -0.036934666666666664, -1.1960133333333334,
  0.9448566666666667, -0.5575933333333333, -1.1789433333333332, 1.01481,
  -0.55033, -1.1987466666666668, 1.0381266666666666, -0.5587566666666667,
  -1.2390366666666668, 1.01481, -0.5758966666666667, -1.2834233333333331,
  0.9448566666666667, -0.5947833333333333, -0.9141266666666666,
  0.9448566666666667, -0.9787633333333333, -0.90099, 1.01481,
  -0.9656233333333333, -0.91623, 1.0381266666666666, -0.9808666666666667,
  -0.9472400000000001, 1.01481, -1.0118733333333334, -0.9814033333333333,
  0.9448566666666667, -1.0460366666666667, -0.49295666666666665,
  0.9448566666666667, -1.2606499999999998, -0.48569333333333337, 1.01481,
  -1.2435766666666666, -0.49412, 1.0381266666666666, -1.2633800000000002,
  -0.5112633333333333, 1.01481, -1.30367, -0.5301466666666667,
  0.9448566666666667, -1.3480566666666667, 0.027700833333333334,
  0.9448566666666667, -1.3634533333333332, 0.027700833333333334, 1.01481,
  -1.3449466666666665, 0.027700833333333334, 1.0381266666666666,
  -1.3664166666666666, 0.027700833333333334, 1.01481, -1.41009,
  0.027700833333333334, 0.9448566666666667, -1.4582066666666667,
  0.5483600000000001, 0.9448566666666667, -1.2606499999999998,
  0.5410966666666667, 1.01481, -1.2435766666666666, 0.5495233333333334,
  1.0381266666666666, -1.2633800000000002, 0.5666633333333333, 1.01481,
  -1.30367, 0.58555, 0.9448566666666667, -1.3480566666666667, 0.96953,
  0.9448566666666667, -0.9787633333333333, 0.9563900000000001, 1.01481,
  -0.9656233333333333, 0.9716333333333333, 1.0381266666666666,
  -0.9808666666666667, 1.00264, 1.01481, -1.0118733333333334,
  1.0368033333333333, 0.9448566666666667, -1.0460366666666667,
  1.2514166666666666, 0.9448566666666667, -0.5575933333333333,
  1.2343433333333331, 1.01481, -0.55033, 1.2541466666666667, 1.0381266666666666,
  -0.5587566666666667, 1.2944366666666667, 1.01481, -0.5758966666666667,
  1.3388233333333335, 0.9448566666666667, -0.5947833333333333, 1.62293,
  0.5728833333333333, -0.036934666666666664, 1.77468, 0.20757133333333336,
  -0.036934666666666664, 1.8820166666666667, -0.14441533333333334,
  -0.036934666666666664, 1.92273, -0.47641666666666665, -0.036934666666666664,
  1.4993, 0.5728833333333333, 0.5891933333333333, 1.63929, 0.20757133333333336,
  0.6487533333333333, 1.7383066666666667, -0.14441533333333334,
  0.6908833333333334, 1.7758633333333333, -0.47641666666666665,
  0.7068633333333333, 1.1603133333333333, 0.5728833333333333,
  1.0956766666666666, 1.2680566666666666, 0.20757133333333336,
  1.2034200000000002, 1.3442633333333334, -0.14441533333333334, 1.27963,
  1.3731699999999998, -0.47641666666666665, 1.3085366666666667,
  0.6538266666666667, 0.5728833333333333, 1.4346633333333334,
  0.7133900000000001, 0.20757133333333336, 1.5746533333333335,
  0.7555200000000001, -0.14441533333333334, 1.67367, 0.7715,
  -0.47641666666666665, 1.71123, 0.027700833333333334, 0.5728833333333333,
  1.5582933333333335, 0.027700833333333334, 0.20757133333333336,
  1.7100433333333334, 0.027700833333333334, -0.14441533333333334, 1.81738,
  0.027700833333333334, -0.47641666666666665, 1.8580933333333334,
  -0.5984266666666667, 0.5728833333333333, 1.4346633333333334, -0.65799,
  0.20757133333333336, 1.5746533333333335, -0.7001166666666666,
  -0.14441533333333334, 1.67367, -0.7160966666666667, -0.47641666666666665,
  1.71123, -1.1049133333333332, 0.5728833333333333, 1.0956766666666666,
  -1.2126533333333334, 0.20757133333333336, 1.2034200000000002,
  -1.2888633333333332, -0.14441533333333334, 1.27963, -1.3177699999999999,
  -0.47641666666666665, 1.3085366666666667, -1.4438966666666666,
  0.5728833333333333, 0.5891933333333333, -1.5838866666666667,
  0.20757133333333336, 0.6487533333333333, -1.6829033333333332,
  -0.14441533333333334, 0.6908833333333334, -1.7204633333333335,
  -0.47641666666666665, 0.7068633333333333, -1.5675266666666665,
  0.5728833333333333, -0.036934666666666664, -1.7192800000000001,
  0.20757133333333336, -0.036934666666666664, -1.8266133333333334,
  -0.14441533333333334, -0.036934666666666664, -1.8673266666666666,
  -0.47641666666666665, -0.036934666666666664, -1.4438966666666666,
  0.5728833333333333, -0.6630633333333333, -1.5838866666666667,
  0.20757133333333336, -0.7226233333333333, -1.6829033333333332,
  -0.14441533333333334, -0.7647533333333333, -1.7204633333333335,
  -0.47641666666666665, -0.7807333333333334, -1.1049133333333332,
  0.5728833333333333, -1.1695466666666665, -1.2126533333333334,
  0.20757133333333336, -1.27729, -1.2888633333333332, -0.14441533333333334,
  -1.3534966666666668, -1.3177699999999999, -0.47641666666666665,
  -1.3824033333333332, -0.5984266666666667, 0.5728833333333333,
  -1.5085333333333333, -0.65799, 0.20757133333333336, -1.6485233333333333,
  -0.7001166666666666, -0.14441533333333334, -1.74754, -0.7160966666666667,
  -0.47641666666666665, -1.7850966666666668, 0.027700833333333334,
  0.5728833333333333, -1.6321633333333334, 0.027700833333333334,
  0.20757133333333336, -1.7839133333333335, 0.027700833333333334,
  -0.14441533333333334, -1.8912499999999999, 0.027700833333333334,
  -0.47641666666666665, -1.9319633333333333, 0.6538266666666667,
  0.5728833333333333, -1.5085333333333333, 0.7133900000000001,
  0.20757133333333336, -1.6485233333333333, 0.7555200000000001,
  -0.14441533333333334, -1.74754, 0.7715, -0.47641666666666665,
  -1.7850966666666668, 1.1603133333333333, 0.5728833333333333,
  -1.1695466666666665, 1.2680566666666666, 0.20757133333333336, -1.27729,
  1.3442633333333334, -0.14441533333333334, -1.3534966666666668,
  1.3731699999999998, -0.47641666666666665, -1.3824033333333332, 1.4993,
  0.5728833333333333, -0.6630633333333333, 1.63929, 0.20757133333333336,
  -0.7226233333333333, 1.7383066666666667, -0.14441533333333334,
  -0.7647533333333333, 1.7758633333333333, -0.47641666666666665,
  -0.7807333333333334, 1.8487033333333334, -0.7573399999999999,
  -0.036934666666666664, 1.6858499999999998, -0.9649766666666667,
  -0.036934666666666664, 1.5229966666666668, -1.1059933333333334,
  -0.036934666666666664, 1.4489733333333334, -1.18705, -0.036934666666666664,
  1.7075766666666667, -0.7573399999999999, 0.6778099999999999,
  1.5573433333333333, -0.9649766666666667, 0.6138899999999999,
  1.4071099999999999, -1.1059933333333334, 0.54997, 1.3388233333333335,
  -1.18705, 0.5209133333333333, 1.3206133333333334, -0.7573399999999999,
  1.2559766666666665, 1.2049866666666667, -0.9649766666666667,
  1.1403533333333333, 1.0893599999999999, -1.1059933333333334,
  1.0247266666666668, 1.0368033333333333, -1.18705, 0.9721666666666666,
  0.7424433333333333, -0.7573399999999999, 1.64294, 0.6785233333333333,
  -0.9649766666666667, 1.49271, 0.6146033333333333, -1.1059933333333334,
  1.3424766666666665, 0.58555, -1.18705, 1.2741866666666666,
  0.027700833333333334, -0.7573399999999999, 1.78407, 0.027700833333333334,
  -0.9649766666666667, 1.6212166666666665, 0.027700833333333334,
  -1.1059933333333334, 1.4583599999999999, 0.027700833333333334, -1.18705,
  1.3843366666666665, -0.6870433333333333, -0.7573399999999999, 1.64294,
  -0.6231233333333334, -0.9649766666666667, 1.49271, -0.5592033333333333,
  -1.1059933333333334, 1.3424766666666665, -0.5301466666666667, -1.18705,
  1.2741866666666666, -1.2652133333333333, -0.7573399999999999,
  1.2559766666666665, -1.1495866666666668, -0.9649766666666667,
  1.1403533333333333, -1.03396, -1.1059933333333334, 1.0247266666666668,
  -0.9814033333333333, -1.18705, 0.9721666666666666, -1.6521766666666666,
  -0.7573399999999999, 0.6778099999999999, -1.5019433333333334,
  -0.9649766666666667, 0.6138899999999999, -1.35171, -1.1059933333333334,
  0.54997, -1.2834233333333331, -1.18705, 0.5209133333333333,
  -1.7933033333333335, -0.7573399999999999, -0.036934666666666664, -1.63045,
  -0.9649766666666667, -0.036934666666666664, -1.4675966666666667,
  -1.1059933333333334, -0.036934666666666664, -1.39357, -1.18705,
  -0.036934666666666664, -1.6521766666666666, -0.7573399999999999, -0.75168,
  -1.5019433333333334, -0.9649766666666667, -0.6877566666666667, -1.35171,
  -1.1059933333333334, -0.6238366666666667, -1.2834233333333331, -1.18705,
  -0.5947833333333333, -1.2652133333333333, -0.7573399999999999,
  -1.3298466666666668, -1.1495866666666668, -0.9649766666666667, -1.21422,
  -1.03396, -1.1059933333333334, -1.0985933333333333, -0.9814033333333333,
  -1.18705, -1.0460366666666667, -0.6870433333333333, -0.7573399999999999,
  -1.71681, -0.6231233333333334, -0.9649766666666667, -1.5665766666666667,
  -0.5592033333333333, -1.1059933333333334, -1.4163433333333333,
  -0.5301466666666667, -1.18705, -1.3480566666666667, 0.027700833333333334,
  -0.7573399999999999, -1.85794, 0.027700833333333334, -0.9649766666666667,
  -1.6950833333333333, 0.027700833333333334, -1.1059933333333334, -1.53223,
  0.027700833333333334, -1.18705, -1.4582066666666667, 0.7424433333333333,
  -0.7573399999999999, -1.71681, 0.6785233333333333, -0.9649766666666667,
  -1.5665766666666667, 0.6146033333333333, -1.1059933333333334,
  -1.4163433333333333, 0.58555, -1.18705, -1.3480566666666667,
  1.3206133333333334, -0.7573399999999999, -1.3298466666666668,
  1.2049866666666667, -0.9649766666666667, -1.21422, 1.0893599999999999,
  -1.1059933333333334, -1.0985933333333333, 1.0368033333333333, -1.18705,
  -1.0460366666666667, 1.7075766666666667, -0.7573399999999999, -0.75168,
  1.5573433333333333, -0.9649766666666667, -0.6877566666666667,
  1.4071099999999999, -1.1059933333333334, -0.6238366666666667,
  1.3388233333333335, -1.18705, -0.5947833333333333, 1.41677, -1.23924,
  -0.036934666666666664, 1.2446633333333332, -1.2847633333333335,
  -0.036934666666666664, 0.8193933333333334, -1.3169633333333333,
  -0.036934666666666664, 0.027700833333333334, -1.32918, -0.036934666666666664,
  1.30912, -1.23924, 0.5082766666666667, 1.15035, -1.2847633333333335,
  0.44072333333333336, 0.7580366666666667, -1.3169633333333333,
  0.2738046666666667, 1.01394, -1.23924, 0.9493066666666666, 0.8917433333333334,
  -1.2847633333333335, 0.82711, 0.5898033333333333, -1.3169633333333333,
  0.5251666666666667, 0.57291, -1.23924, 1.2444833333333334, 0.50536,
  -1.2847633333333335, 1.0857133333333333, 0.33844, -1.3169633333333333,
  0.6934033333333333, 0.027700833333333334, -1.23924, 1.3521366666666668,
  0.027700833333333334, -1.2847633333333335, 1.18003, 0.027700833333333334,
  -1.3169633333333333, 0.7547566666666667, -0.51751, -1.23924,
  1.2444833333333334, -0.44995666666666667, -1.2847633333333335,
  1.0857133333333333, -0.28303833333333334, -1.3169633333333333,
  0.6934033333333333, -0.95854, -1.23924, 0.9493066666666666,
  -0.8363433333333333, -1.2847633333333335, 0.82711, -0.5344,
  -1.3169633333333333, 0.5251666666666667, -1.2537166666666666, -1.23924,
  0.5082766666666667, -1.0949466666666665, -1.2847633333333335,
  0.44072333333333336, -0.7026366666666667, -1.3169633333333333,
  0.2738046666666667, -1.36137, -1.23924, -0.036934666666666664,
  -1.1892633333333333, -1.2847633333333335, -0.036934666666666664,
  -0.7639933333333333, -1.3169633333333333, -0.036934666666666664,
  -1.2537166666666666, -1.23924, -0.5821433333333333, -1.0949466666666665,
  -1.2847633333333335, -0.5145933333333333, -0.7026366666666667,
  -1.3169633333333333, -0.34767333333333333, -0.95854, -1.23924,
  -1.0231733333333333, -0.8363433333333333, -1.2847633333333335, -0.90098,
  -0.5344, -1.3169633333333333, -0.5990366666666667, -0.51751, -1.23924,
  -1.3183533333333335, -0.44995666666666667, -1.2847633333333335,
  -1.1595833333333334, -0.28303833333333334, -1.3169633333333333, -0.76727,
  0.027700833333333334, -1.23924, -1.4260066666666666, 0.027700833333333334,
  -1.2847633333333335, -1.2538966666666667, 0.027700833333333334,
  -1.3169633333333333, -0.8286266666666666, 0.57291, -1.23924,
  -1.3183533333333335, 0.50536, -1.2847633333333335, -1.1595833333333334,
  0.33844, -1.3169633333333333, -0.76727, 1.01394, -1.23924,
  -1.0231733333333333, 0.8917433333333334, -1.2847633333333335, -0.90098,
  0.5898033333333333, -1.3169633333333333, -0.5990366666666667, 1.30912,
  -1.23924, -0.5821433333333333, 1.15035, -1.2847633333333335,
  -0.5145933333333333, 0.7580366666666667, -1.3169633333333333,
  -0.34767333333333333, -1.4883233333333334, 0.5895366666666667,
  -0.036934666666666664, -1.93099, 0.5862066666666668, -0.036934666666666664,
  -2.2581766666666665, 0.56289, -0.036934666666666664, -2.461003333333333,
  0.49959666666666663, -0.036934666666666664, -2.5305866666666663,
  0.37634666666666666, -0.036934666666666664, -1.4735166666666666, 0.62285,
  0.12295866666666666, -1.9441733333333333, 0.6189966666666666,
  0.12295866666666666, -2.2896366666666665, 0.5920366666666667,
  0.12295866666666666, -2.5024133333333336, 0.5188566666666666,
  0.12295866666666666, -2.5750033333333335, 0.37634666666666666,
  0.12295866666666666, -1.4409466666666666, 0.6961333333333334,
  0.17625633333333332, -1.9731833333333335, 0.6911366666666667,
  0.17625633333333332, -2.35885, 0.65616, 0.17625633333333332, -2.59351,
  0.5612233333333333, 0.17625633333333332, -2.6727133333333333,
  0.37634666666666666, 0.17625633333333332, -1.4083766666666666,
  0.7694166666666666, 0.12295866666666666, -2.00219, 0.7632766666666666,
  0.12295866666666666, -2.4280633333333332, 0.7202833333333333,
  0.12295866666666666, -2.6846033333333335, 0.6035900000000001,
  0.12295866666666666, -2.7704266666666664, 0.37634666666666666,
  0.12295866666666666, -1.39357, 0.80273, -0.036934666666666664,
  -2.0153766666666666, 0.7960666666666667, -0.036934666666666664,
  -2.4595233333333337, 0.74943, -0.036934666666666664, -2.7260133333333334,
  0.62285, -0.036934666666666664, -2.8148433333333336, 0.37634666666666666,
  -0.036934666666666664, -1.4083766666666666, 0.7694166666666666,
  -0.19682766666666665, -2.00219, 0.7632766666666666, -0.19682766666666665,
  -2.4280633333333332, 0.7202833333333333, -0.19682766666666665,
  -2.6846033333333335, 0.6035900000000001, -0.19682766666666665,
  -2.7704266666666664, 0.37634666666666666, -0.19682766666666665,
  -1.4409466666666666, 0.6961333333333334, -0.2501253333333333,
  -1.9731833333333335, 0.6911366666666667, -0.2501253333333333, -2.35885,
  0.65616, -0.2501253333333333, -2.59351, 0.5612233333333333,
  -0.2501253333333333, -2.6727133333333333, 0.37634666666666666,
  -0.2501253333333333, -1.4735166666666666, 0.62285, -0.19682766666666665,
  -1.9441733333333333, 0.6189966666666666, -0.19682766666666665,
  -2.2896366666666665, 0.5920366666666667, -0.19682766666666665,
  -2.5024133333333336, 0.5188566666666666, -0.19682766666666665,
  -2.5750033333333335, 0.37634666666666666, -0.19682766666666665,
  -2.4935766666666668, 0.18314333333333332, -0.036934666666666664,
  -2.3766166666666666, -0.05003433333333333, -0.036934666666666664,
  -2.1708266666666662, -0.2832113333333333, -0.036934666666666664,
  -1.8673266666666666, -0.47641666666666665, -0.036934666666666664,
  -2.533943333333333, 0.16449233333333335, 0.12295866666666666, -2.4053,
  -0.07848733333333334, 0.12295866666666666, -2.1808899999999998,
  -0.31834433333333334, 0.12295866666666666, -1.8525233333333333, -0.52083,
  0.12295866666666666, -2.6227466666666666, 0.123461, 0.17625633333333332,
  -2.4684066666666666, -0.1410843333333333, 0.17625633333333332, -2.20303,
  -0.39563666666666664, 0.17625633333333332, -1.8199533333333333,
  -0.6185433333333333, 0.17625633333333332, -2.7115533333333333,
  0.08242933333333333, 0.12295866666666666, -2.5315133333333337,
  -0.20368133333333333, 0.12295866666666666, -2.2251666666666665,
  -0.47292666666666666, 0.12295866666666666, -1.78738, -0.7162566666666667,
  0.12295866666666666, -2.7519199999999997, 0.06377866666666666,
  -0.036934666666666664, -2.5601966666666667, -0.23213466666666666,
  -0.036934666666666664, -2.23523, -0.50806, -0.036934666666666664,
  -1.7725766666666667, -0.76067, -0.036934666666666664, -2.7115533333333333,
  0.08242933333333333, -0.19682766666666665, -2.5315133333333337,
  -0.20368133333333333, -0.19682766666666665, -2.2251666666666665,
  -0.47292666666666666, -0.19682766666666665, -1.78738, -0.7162566666666667,
  -0.19682766666666665, -2.6227466666666666, 0.123461, -0.2501253333333333,
  -2.4684066666666666, -0.1410843333333333, -0.2501253333333333, -2.20303,
  -0.39563666666666664, -0.2501253333333333, -1.8199533333333333,
  -0.6185433333333333, -0.2501253333333333, -2.533943333333333,
  0.16449233333333335, -0.19682766666666665, -2.4053, -0.07848733333333334,
  -0.19682766666666665, -2.1808899999999998, -0.31834433333333334,
  -0.19682766666666665, -1.8525233333333333, -0.52083, -0.19682766666666665,
  1.6384766666666666, 0.0210294, -0.036934666666666664, 2.0929866666666666,
  0.125404, -0.036934666666666664, 2.28989, 0.37634666666666666,
  -0.036934666666666664, 2.3979666666666666, 0.6805866666666667,
  -0.036934666666666664, 2.5859900000000002, 0.9448566666666667,
  -0.036934666666666664, 1.6384766666666666, -0.10111099999999999, 0.31483,
  2.127683333333333, 0.03483966666666667, 0.28068633333333337,
  2.334306666666667, 0.32360466666666665, 0.20556999999999997,
  2.452096666666667, 0.6615033333333333, 0.13045366666666666, 2.67482,
  0.9448566666666667, 0.09630966666666667, 1.6384766666666666, -0.36982,
  0.4320866666666667, 2.2040233333333332, -0.164402, 0.38656,
  2.4320166666666667, 0.20757133333333336, 0.2864046666666667,
  2.571183333333333, 0.6195166666666666, 0.18624966666666667,
  2.8702433333333333, 0.9448566666666667, 0.14072433333333334,
  1.6384766666666666, -0.6385299999999999, 0.31483, 2.28036,
  -0.3636433333333333, 0.28068633333333337, 2.5297300000000003, 0.091538,
  0.20556999999999997, 2.69027, 0.5775333333333333, 0.13045366666666666,
  3.0656666666666665, 0.9448566666666667, 0.09630966666666667,
  1.6384766666666666, -0.76067, -0.036934666666666664, 2.3150600000000003,
  -0.4542066666666667, -0.036934666666666664, 2.5741466666666666,
  0.03879533333333333, -0.036934666666666664, 2.744403333333333,
  0.5584466666666666, -0.036934666666666664, 3.1544966666666667,
  0.9448566666666667, -0.036934666666666664, 1.6384766666666666,
  -0.6385299999999999, -0.3887, 2.28036, -0.3636433333333333,
  -0.35455666666666663, 2.5297300000000003, 0.091538, -0.279439, 2.69027,
  0.5775333333333333, -0.20432266666666665, 3.0656666666666665,
  0.9448566666666667, -0.17017866666666667, 1.6384766666666666, -0.36982,
  -0.5059533333333334, 2.2040233333333332, -0.164402, -0.46043,
  2.4320166666666667, 0.20757133333333336, -0.36027333333333333,
  2.571183333333333, 0.6195166666666666, -0.26011866666666666,
  2.8702433333333333, 0.9448566666666667, -0.21459333333333333,
  1.6384766666666666, -0.10111099999999999, -0.3887, 2.127683333333333,
  0.03483966666666667, -0.35455666666666663, 2.334306666666667,
  0.32360466666666665, -0.279439, 2.452096666666667, 0.6615033333333333,
  -0.20432266666666665, 2.67482, 0.9448566666666667, -0.17017866666666667,
  2.6540899999999996, 0.98483, -0.036934666666666664, 2.7044300000000003,
  0.9981533333333333, -0.036934666666666664, 2.7192333333333334, 0.98483,
  -0.036934666666666664, 2.68074, 0.9448566666666667, -0.036934666666666664,
  2.749223333333333, 0.98678, 0.087982, 2.79372, 1.0012766666666666, 0.069661,
  2.7950500000000003, 0.98756, 0.051339666666666665, 2.7399600000000004,
  0.9448566666666667, 0.043012, 2.9585166666666667, 0.9910733333333333,
  0.12962066666666666, 2.9901633333333333, 1.0081466666666665,
  0.10519266666666667, 2.96185, 0.9935733333333334, 0.08076466666666667,
  2.8702433333333333, 0.9448566666666667, 0.069661, 3.1678100000000002, 0.99537,
  0.087982, 3.186606666666667, 1.0150166666666667, 0.069661, 3.1286466666666666,
  0.9995833333333334, 0.051339666666666665, 3.0005266666666666,
  0.9448566666666667, 0.043012, 3.2629433333333333, 0.99732,
  -0.036934666666666664, 3.2758966666666667, 1.01814, -0.036934666666666664,
  3.2044633333333334, 1.0023166666666667, -0.036934666666666664,
  3.059746666666667, 0.9448566666666667, -0.036934666666666664,
  3.1678100000000002, 0.99537, -0.161851, 3.186606666666667, 1.0150166666666667,
  -0.14353000000000002, 3.1286466666666666, 0.9995833333333334,
  -0.12520866666666666, 3.0005266666666666, 0.9448566666666667, -0.116881,
  2.9585166666666667, 0.9910733333333333, -0.20348966666666665,
  2.9901633333333333, 1.0081466666666665, -0.17906166666666667, 2.96185,
  0.9935733333333334, -0.15463366666666667, 2.8702433333333333,
  0.9448566666666667, -0.14353000000000002, 2.749223333333333, 0.98678,
  -0.161851, 2.79372, 1.0012766666666666, -0.14353000000000002,
  2.7950500000000003, 0.98756, -0.12520866666666666, 2.7399600000000004,
  0.9448566666666667, -0.116881, 0.027700833333333334, 1.6554900000000001,
  -0.036934666666666664, 0.3504466666666667, 1.6088566666666668,
  -0.036934666666666664, 0.33564333333333335, 1.4956, -0.036934666666666664,
  0.21424266666666666, 1.3556933333333334, -0.036934666666666664,
  0.21720366666666668, 1.22911, -0.036934666666666664, 0.32554733333333336,
  1.6088566666666668, 0.09008100000000001, 0.31187733333333334, 1.4956,
  0.08423266666666666, 0.19982333333333333, 1.3556933333333334,
  0.036395666666666666, 0.20251733333333333, 1.22911, 0.03744533333333333,
  0.257151, 1.6088566666666668, 0.19251566666666667, 0.24660633333333332,
  1.4956, 0.181971, 0.16024533333333332, 1.3556933333333334, 0.09561, 0.162248,
  1.22911, 0.09761266666666667, 0.15471633333333334, 1.6088566666666668,
  0.260912, 0.14886799999999997, 1.4956, 0.247242, 0.10103100000000001,
  1.3556933333333334, 0.135188, 0.10208066666666667, 1.22911,
  0.13788199999999998, 0.027700833333333334, 1.6088566666666668,
  0.28581266666666666, 0.027700833333333334, 1.4956, 0.27100766666666665,
  0.027700833333333334, 1.3556933333333334, 0.14960733333333334,
  0.027700833333333334, 1.22911, 0.15256833333333333, -0.09931466666666666,
  1.6088566666666668, 0.260912, -0.09346633333333335, 1.4956, 0.247242,
  -0.045629333333333334, 1.3556933333333334, 0.135188, -0.046679, 1.22911,
  0.13788199999999998, -0.20174933333333334, 1.6088566666666668,
  0.19251566666666667, -0.19120466666666666, 1.4956, 0.181971,
  -0.10484366666666665, 1.3556933333333334, 0.09561, -0.10684633333333333,
  1.22911, 0.09761266666666667, -0.2701456666666667, 1.6088566666666668,
  0.09008100000000001, -0.25647566666666666, 1.4956, 0.08423266666666666,
  -0.14442166666666667, 1.3556933333333334, 0.036395666666666666,
  -0.14711566666666667, 1.22911, 0.03744533333333333, -0.29504633333333335,
  1.6088566666666668, -0.036934666666666664, -0.28024133333333334, 1.4956,
  -0.036934666666666664, -0.15884099999999998, 1.3556933333333334,
  -0.036934666666666664, -0.16180199999999997, 1.22911, -0.036934666666666664,
  -0.2701456666666667, 1.6088566666666668, -0.16394999999999998,
  -0.25647566666666666, 1.4956, -0.15810166666666667, -0.14442166666666667,
  1.3556933333333334, -0.11026466666666666, -0.14711566666666667, 1.22911,
  -0.11131433333333333, -0.20174933333333334, 1.6088566666666668,
  -0.26638466666666666, -0.19120466666666666, 1.4956, -0.25584,
  -0.10484366666666665, 1.3556933333333334, -0.169479, -0.10684633333333333,
  1.22911, -0.17148166666666667, -0.09931466666666666, 1.6088566666666668,
  -0.33478, -0.09346633333333335, 1.4956, -0.32111100000000004,
  -0.045629333333333334, 1.3556933333333334, -0.209057, -0.046679, 1.22911,
  -0.211751, 0.027700833333333334, 1.6088566666666668, -0.35968,
  0.027700833333333334, 1.4956, -0.34487666666666666, 0.027700833333333334,
  1.3556933333333334, -0.22347633333333333, 0.027700833333333334, 1.22911,
  -0.22643733333333332, 0.15471633333333334, 1.6088566666666668, -0.33478,
  0.14886799999999997, 1.4956, -0.32111100000000004, 0.10103100000000001,
  1.3556933333333334, -0.209057, 0.10208066666666667, 1.22911, -0.211751,
  0.257151, 1.6088566666666668, -0.26638466666666666, 0.24660633333333332,
  1.4956, -0.25584, 0.16024533333333332, 1.3556933333333334, -0.169479,
  0.162248, 1.22911, -0.17148166666666667, 0.32554733333333336,
  1.6088566666666668, -0.16394999999999998, 0.31187733333333334, 1.4956,
  -0.15810166666666667, 0.19982333333333333, 1.3556933333333334,
  -0.11026466666666666, 0.20251733333333333, 1.22911, -0.11131433333333333,
  0.4600033333333334, 1.1447233333333335, -0.036934666666666664, 0.8094,
  1.0869833333333332, -0.036934666666666664, 1.1232633333333333,
  1.0292433333333333, -0.036934666666666664, 1.25947, 0.9448566666666667,
  -0.036934666666666664, 0.4265, 1.1447233333333335, 0.13274466666666668,
  0.74882, 1.0869833333333332, 0.26988233333333334, 1.0383566666666666,
  1.0292433333333333, 0.39307333333333333, 1.1640066666666666,
  0.9448566666666667, 0.44653333333333334, 0.33463666666666664,
  1.1447233333333335, 0.270001, 0.5827066666666667, 1.0869833333333332,
  0.5180733333333333, 0.80555, 1.0292433333333333, 0.7409166666666667,
  0.9022566666666666, 0.9448566666666667, 0.8376199999999999, 0.19738,
  1.1447233333333335, 0.36186666666666667, 0.3345166666666667,
  1.0869833333333332, 0.6841833333333334, 0.45770999999999995,
  1.0292433333333333, 0.9737233333333334, 0.51117, 0.9448566666666667,
  1.0993733333333333, 0.027700833333333334, 1.1447233333333335, 0.39537,
  0.027700833333333334, 1.0869833333333332, 0.7447633333333333,
  0.027700833333333334, 1.0292433333333333, 1.05863, 0.027700833333333334,
  0.9448566666666667, 1.1948333333333332, -0.14197833333333335,
  1.1447233333333335, 0.36186666666666667, -0.27911600000000003,
  1.0869833333333332, 0.6841833333333334, -0.4023066666666667,
  1.0292433333333333, 0.9737233333333334, -0.45576666666666665,
  0.9448566666666667, 1.0993733333333333, -0.27923466666666663,
  1.1447233333333335, 0.270001, -0.5273066666666667, 1.0869833333333332,
  0.5180733333333333, -0.75015, 1.0292433333333333, 0.7409166666666667,
  -0.8468533333333333, 0.9448566666666667, 0.8376199999999999, -0.3711,
  1.1447233333333335, 0.13274466666666668, -0.6934166666666666,
  1.0869833333333332, 0.26988233333333334, -0.9829566666666667,
  1.0292433333333333, 0.39307333333333333, -1.1086066666666667,
  0.9448566666666667, 0.44653333333333334, -0.4046033333333333,
  1.1447233333333335, -0.036934666666666664, -0.754, 1.0869833333333332,
  -0.036934666666666664, -1.0678633333333332, 1.0292433333333333,
  -0.036934666666666664, -1.2040666666666666, 0.9448566666666667,
  -0.036934666666666664, -0.3711, 1.1447233333333335, -0.20661366666666667,
  -0.6934166666666666, 1.0869833333333332, -0.34375, -0.9829566666666667,
  1.0292433333333333, -0.4669433333333333, -1.1086066666666667,
  0.9448566666666667, -0.5204033333333333, -0.27923466666666663,
  1.1447233333333335, -0.34387, -0.5273066666666667, 1.0869833333333332,
  -0.5919399999999999, -0.75015, 1.0292433333333333, -0.8147833333333333,
  -0.8468533333333333, 0.9448566666666667, -0.91149, -0.14197833333333335,
  1.1447233333333335, -0.4357333333333333, -0.27911600000000003,
  1.0869833333333332, -0.7580533333333332, -0.4023066666666667,
  1.0292433333333333, -1.04759, -0.45576666666666665, 0.9448566666666667,
  -1.17324, 0.027700833333333334, 1.1447233333333335, -0.46923666666666664,
  0.027700833333333334, 1.0869833333333332, -0.8186333333333333,
  0.027700833333333334, 1.0292433333333333, -1.1324966666666667,
  0.027700833333333334, 0.9448566666666667, -1.2687033333333335, 0.19738,
  1.1447233333333335, -0.4357333333333333, 0.3345166666666667,
  1.0869833333333332, -0.7580533333333332, 0.45770999999999995,
  1.0292433333333333, -1.04759, 0.51117, 0.9448566666666667, -1.17324,
  0.33463666666666664, 1.1447233333333335, -0.34387, 0.5827066666666667,
  1.0869833333333332, -0.5919399999999999, 0.80555, 1.0292433333333333,
  -0.8147833333333333, 0.9022566666666666, 0.9448566666666667, -0.91149, 0.4265,
  1.1447233333333335, -0.20661366666666667, 0.74882, 1.0869833333333332,
  -0.34375, 1.0383566666666666, 1.0292433333333333, -0.4669433333333333,
  1.1640066666666666, 0.9448566666666667, -0.5204033333333333,
];

export const SOURCE_FS = `
  varying lowp vec4 vColor;
  varying highp vec3 vLighting;

  void main(void) {
    gl_FragColor = vec4(vColor.rgb * vLighting, vColor.a);
  }
`;

export const SOURCE_VS = `
  attribute vec3 aVertexNormal;
  attribute vec4 aVertexPosition;
  uniform vec4 uColor;
  uniform mat4 uProjectionMatrix;
  uniform mat4 uModelViewMatrix;
  uniform mat4 uNormalMatrix;
  varying lowp vec4 vColor;
  varying highp vec3 vLighting;

  void main(void) {
    gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
    vColor = uColor;
    highp vec3 ambientLight = vec3(0.3, 0.3, 0.3);
    highp vec3 directionalLightColor = vec3(1, 1, 1);
    highp vec3 directionalVector = normalize(vec3(0.85, 0.8, 0.75));
    highp vec4 transformedNormal = uNormalMatrix * vec4(aVertexNormal, 1.0);
    highp float directional = max(dot(transformedNormal.xyz, directionalVector), 0.0);
    vLighting = ambientLight + (directionalLightColor * directional);
  }
`;
